<template>
  <layout>
    <template #header>
      <ui-header :title=" $t('support.matis.title')"/>
    </template>
    <ui-content>
      <h6 class="card-subtitle text-muted pb-3">
        Provide organisation ID filter to load matis uploads
      </h6>
      <ag-grid-vue
          :serverSideDatasource="grid.datasource('api/matisuploads/support/list')"
          :grid-options="grid.defaultOptions"
          :columnDefs="columnDefs"
          class="ag-theme-quartz"
          style="min-height:100%"
          @grid-ready="onGridReady"
          @grid-pre-destroyed="onGridPreDestroyed"
          @filter-changed="onFilterChanged"
          :initialState="initialState"
          :getRowId="getRowId"
      />
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import grid from '@/util/grid'
import {inject, onBeforeUnmount, ref} from 'vue'
import session from '@/util/session'
import {DateFormatter} from '@/services/dateFormatter'
import {AgGridVue} from 'ag-grid-vue3'
import matisStateColorRenderer from '@/util/matisStateColorRenderer'

export default {
  name:       'Support-Matis',
  computed:   {
    grid() {
      return grid
    },
  },
  components: {
    AgGridVue,
    UiContent,
    Layout,
    UiHeader,
    // eslint-disable-next-line
    matisStateColorRenderer,
  },
  setup:      () => {
    const $t = inject('$t')

    const gridKey            = 'matis-list'
    const initialState       = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged    = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api           = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })

    const resize = () => {
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
    })

    const resetGrid = () => {
      api?.setFilterModel(null)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const columnDefs = [
      {
        headerName: $t('matis.list.id'),
        field:      'matisIdentification',
        maxWidth:   120,
      },
      {
        headerName: 'Org ID',
        field:      'organisationId',
        maxWidth:   100,
      },
      {
        headerName:     'Date',
        filter:         'agDateColumnFilter',
        field:          'uploadDate',
        valueGetter:    (params) => {
          return new Date(params.data.uploadDateTime)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.uploadDateTime)
        },
        filterParams:   {
          minValidYear:  2000,
          maxValidYear:  2099,
          filterOptions: ['inRange'],
        },
        maxWidth:       100,
      },
      {
        headerName: 'Ref ID',
        field:      'matisReferenceId',
        maxWidth:   100,
      },
      {
        headerName:        'State',
        field:             'uploaded',
        cellRenderer:      'matisStateColorRenderer',
        filter:            'agSetColumnFilter',
        filterParams:      {
          values: ['DONE', 'ERROR'],
        },
        valueGetter:       (params) => {
          return params.data.matisReferenceId ? 'DONE' : 'ERROR'
        },
        comparator:        grid.caseInsensitiveStateComparator('matis.state.'),
        filterValueGetter: (params) => {
          return $t('matis.state.' + params.data.state)
        },
        maxWidth:          100,
      },
      {
        headerName: $t('matis.list.matis_errors'),
        field:      'matisErrors',
      },
    ]

    columnDefs.forEach((c) => {
      c.checkboxSelection = false
    })

    const getRowId = (row) => {
      return row.data.matisIdentification
    }

    return {
      onGridReady,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      columnDefs,
      getRowId,
      resetGrid,
    }
  },
}
</script>
