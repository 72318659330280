<template>
  <shared-link-driver v-if="shareLinkType === ShareLinkType.TRANSPORTER" :link="link"/>
  <shared-link-processor v-if="shareLinkType === ShareLinkType.PROCESSOR" :link="link"/>
  <shared-link-control v-if="shareLinkType === ShareLinkType.CONTROL" :link="link"/>
  <shared-link-signature-transporter v-if="shareLinkType === ShareLinkType.SIGNATURE_TRANSPORTER" :link="link"/>
  <shared-link-signature-processor v-if="shareLinkType === ShareLinkType.SIGNATURE_PROCESSOR" :link="link"/>
</template>

<script>

import backend from '@/util/backend'
import {provide, ref} from 'vue'
import {useRouter} from 'vue-router'
import ShareLinkType from '@/types/shareLinkType'
import SharedLinkDriver from '@/views/shared-links/Shared-Link-Driver'
import SharedLinkProcessor from '@/views/shared-links/Shared-Link-Processor'
import SharedLinkControl from '@/views/shared-links/Shared-Link-Control'
import session from '@/util/session'
import sharelinkConfig from '@/services/sharelinkConfig'
import SharedLinkSignatureTransporter from '@/views/shared-links/Shared-Link-Signature-Transporter.vue'
import SharedLinkSignatureProcessor from '@/views/shared-links/Shared-Link-Signature-Processor.vue'

export default {
  name:       'SharedLink',
  components: {
    SharedLinkSignatureProcessor,
    SharedLinkSignatureTransporter,
    SharedLinkControl,
    SharedLinkProcessor,
    SharedLinkDriver,
  },
  props:      {
    uuid: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const config = ref(sharelinkConfig)
    provide('config', config)
    provide('isSharelink', true)
    const router        = useRouter()
    const loading       = ref(true)
    const link          = ref(null)
    const shareLinkType = ref(null)
    backend.get(`api/sharedlinks/init?shareLinkUuid=${props.uuid}`).then(async () => {
      await session.setUser()
      config.value.parse(session.getOrganisation())
      backend.get(`api/sharedlinks/${props.uuid}`).then((result) => {
        if (!ShareLinkType.isValidType(result.data.shareLinkType)) {
          throw new Error('Unknown shared link type!')
        }
        shareLinkType.value = result.data.shareLinkType
        link.value          = result.data
      })
    }).catch((e) => {
      if (e !== 'no session') {
        router.push({name: 'SharedExpired'})
      }
    })

    return {
      loading,
      ShareLinkType,
      shareLinkType,
      link,
    }
  },
}
</script>
