<template>
  <div class="pb-1">
    <label class="pe-2" :for="id">
      {{ label }}<span v-if="required" :title="$t('general.required')" class="text-danger">*</span><i v-if="icon"
                                                                                                      style="font-size: 0.75rem; position: absolute"
                                                                                                      :class="icon"
                                                                                                      :title="iconText"/>
    </label>
    <slot>
      <input :id="id" :autocomplete="autocomplete" :readonly="readonly"
             :class="type === 'checkbox' ? 'form-check-input' : readonly ? 'form-control-plaintext' : 'form-control'"
             v-model="model" :required="required"
             :pattern="required ? '.*\\S+.*' : '.*'" :type="type" :placeholder="placeholder" :maxlength="maxlength"
             :min="min" :step="step" :max="max"
             :title="$t('general.required_field')"
      >
    </slot>
  </div>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'

export default {
  name: 'ui-view-card-field',
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: ''
    },
    iconText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: 255,
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    step: {
      type: String,
      default: '',
    },
  },
  setup: (props, {emit}) => {
    const id = 'id-' + Math.random()
    return {
      model: useModelWrapper(props, emit),
      id,
    }
  }
}
</script>
