<template>
  <template v-for="(typeValue, index) in model" :key="typeValue.id">
    <div class="pb-1">
      <label class="pe-2">
        {{ $t('form.labels.type') }}<span v-if="required" :title="$t('general.required')"
                                          class="text-danger">*</span>
      </label>
      <div class="d-flex">
        <div class="container flex-column">
          <div class="row">
            <v-select
                class="col form-control"
                :class="{'col': !$isMobile}"
                :options="types"
                v-model="typeValue.type"
                :reduce="type => type.value"
            >
              <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    :required="required && !typeValue.type"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template #no-options>
                {{ $t('general.no_options') }}
              </template>
            </v-select>
            <input
                class="form-control"
                :class="{
                       'col': !$isMobile,
                     }"
                v-model="typeValue.value"
                :key="typeValue.type"
                :required="required"
                maxlength="30"
            >
          </div>
        </div>
        <div v-if="index === 0" class="btn btn-primary" @click="addTypeValue()">
          <i class="bi-plus"/>
        </div>
        <div v-if="index > 0" class="btn btn-danger" @click="deleteTypeValue(index)">
          <i class="bi-trash"/>
        </div>
      </div>
    </div>
  </template>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'

export default {
  name:       'ui-view-card-type-values',
  components: {},
  props:      {
    modelValue: {
      default: [],
    },
    required:   {
      type:    Boolean,
      default: false,
    },
    types:      {
      type:     Array,
      required: true,
    },
  },
  setup:      (props, {emit}) => {
    const model = useModelWrapper(props, emit)

    const addTypeValue = () => {
      model.value.push({})
    }

    if (model.value.length < 1) {
      console.error('Should have at least one typeValue')
      addTypeValue()
    }

    const deleteTypeValue = (index) => {
      model.value.splice(index, 1)
    }

    return {
      addTypeValue,
      deleteTypeValue,
      model,
    }
  },
}
</script>
