<template>
  <layout>
    <template #header>
      <ui-header :title="$t('my_organisation.title')" back-route="/my-organisation">
        <template #actions>
          <button id="saveBtn" type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()" form="organisation-form"><i class="bi-save" />&nbsp;{{$t('general.save')}}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
        <form id="organisation-form" class="row g-2 justify-content-center">
          <ui-view-card :title="$t('my_organisation.basic_information')">
            <ui-view-card-field :label="$t('my_organisation.name')" v-model="organisation.name" :required="true" />
            <ui-view-card-field :label="$t('my_organisation.contact_email')" :required="true" >
              <v-select :options="orgAdmins" v-model="organisation.contactEmail" :reduce="contact => contact.email" label="email">
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!organisation.contactEmail"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #no-options>
                  {{ $t('general.no_options') }}
                </template>
              </v-select>
            </ui-view-card-field>
            <ui-view-card-language v-model="organisation.language" icon="bi-question-circle-fill" :icon-text="$t('my_organisation.language_title')" />
            <template v-if="$orgType(OrganisationType.REGULAR)">
              <ui-view-card-field :label="$t('my_organisation.contract.auto_renew')" v-model="organisation.autoRenewContract" type="checkbox" />
              <hr />
              <ui-view-card-field :label="$t('my_organisation.config.empty_container_status')" icon="bi-question-circle-fill" :icon-text="$t('my_organisation.empty_container_info')">
                &nbsp;
                <input id="emptyContainerCheckbox" class="form-check-input" type="checkbox" v-model="organisation.config.empty_container">
              </ui-view-card-field>
              <ui-view-card-field :label="$t('my_organisation.config.include_work_slip')" icon="bi-question-circle-fill" :icon-text="$t('my_organisation.include_work_slip_info')">
                &nbsp;
                <input id="workslipCheckbox" class="form-check-input" type="checkbox" v-model="organisation.config.include_work_slip">
              </ui-view-card-field>
              <ui-view-card-field :label="$t('my_organisation.config.annex_7')" icon="bi-question-circle-fill" :icon-text="$t('my_organisation.annex_7_info')">
                &nbsp;
                <input id="annex7Checkbox" class="form-check-input" type="checkbox" v-model="organisation.config.ANNEX_7">
              </ui-view-card-field>
              <ui-view-card-field :label="$t('my_organisation.config.grondbank')" icon="bi-question-circle-fill" :icon-text="$t('my_organisation.grondbank_info')">
                &nbsp;
                <input id="grondbankCheckbox" class="form-check-input" type="checkbox" v-model="organisation.config.GRONDBANK">
              </ui-view-card-field>
            </template>
          </ui-view-card>
        </form>
      </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import UiViewCard from '@/components/ui-view-card'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import {useRouter} from 'vue-router'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import session from '@/util/session'
import OrganisationType from "@/types/organisationType"

export default {
  name: 'MyOrganisationEdit',
  components: {
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  setup: () => {
    const breadcrumbs = [BREADCRUMBS.MY_ORGANISATION, BREADCRUMBS.EDIT]
    const router = useRouter()

    const loading = ref(true)
    const organisation = ref({})
    backend.get(`api/organisations/me`).then((r) => {
      organisation.value = r.data
      organisation.value.config = JSON.parse(organisation.value.config ?? '{}') ?? {}
      loading.value = false
    })

    const orgAdmins = ref([])
    backend.get(`api/users?role=ORGANISATION_ADMIN`).then((r) => {
      orgAdmins.value = r.data
    })

    const saving = ref(false)
    const notifier = Notifier()
    const save = async () => {
      saving.value = true
      const formElement = $('#organisation-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        saving.value = false
        return false
      }

      try {
        organisation.value.config = JSON.stringify(organisation.value.config)
        const result = await backend.put('api/organisations', organisation.value)
        if (result.status === 200) {
          await session.setOrganisation()
          router.push({name: 'MyOrganisationView'})
        } else {
          notifier.error('toast.save_failed')
        }
      } catch (error) {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      loading,
      organisation,
      orgAdmins,
      OrganisationType,
      formatDate: DateFormatter.formatDate,
      save,
      saving,
    }
  }
}
</script>
