<template>
  <input class="col form-control" v-model="value" :required="required" @beforeinput="saveInput" @input="validOrReset"/>
</template>

<script>

import {useModelWrapper} from '@/util/modelWrapper'

export default {
  name:  'NumberInput',
  props: {
    modelValue: {
      type:     [String, Number, null],
      required: true,
    },
    required:   {
      type:    Boolean,
      default: false,
    },
  },
  setup: (props, {emit}) => {
    const value = useModelWrapper(props, emit)
    const regex = /^[0-9]*[,.]{0,1}[0-9]*$/

    let currentVal  = null
    const saveInput = (e) => {
      if (regex.test(e.target.value)) {
        currentVal = e.target.value
      }
    }

    const validOrReset = (e) => {
      if (!regex.test(e.target.value)) {
        value.value = currentVal
      }
    }

    return {
      value,
      saveInput,
      validOrReset,
    }
  },
}

</script>
